import common from './common';

const Player = common.Player

const selectors = {
	video : 'iframe',
}

export const initInstance = function(el) {
	const video = el.querySelector(selectors.video)
	if (!video) return

	const player = new Player(video)

	Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(function(dimensions) {
		const width = dimensions[0];
		const height = dimensions[1];
		const ratio = height/width*100;
		el.style.paddingBottom = `${ratio}%`;

		// send resize event so videoFit resizes based on new padding bottom value
		window.dispatchEvent(new Event('videoRatio:resize'));
	});
}